*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
span{
  color: rgba(255, 169, 69, 1);
}
.a{
  text-decoration: none;
  color: black;
  font-weight: bold;
}
.ol{
  display: flex;
  position: relative;
  left: 400px;
}
li{
  text-decoration: none;
   padding-left: 30px;
  list-style: none;
}
select{
  text-decoration: none;
  border: none;
  font-weight: bold;
}
h1{
  color: #fff;
  text-align: center;
  position: relative;
  top: 35px;
}
.b{
  text-decoration: none;
  color: rgba(28, 28, 28, 1);
}
.b1{
  text-decoration: none;
}
.l{
  display: flex;
  flex-direction: column;
  padding: 4px;
}
.l1{
  display: flex;
  flex-direction: column;
  padding: 8px;
}
.flex{
  display: flex;
  gap: 70px;
}
.http{
  height: 25px;
width: 28px;

}
  
.instagram{
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
  bottom: 45px;
  left: 200px;
}
.p1{
  width: 750px;
  position: absolute;
  left: 290px;
  top: 200px;
}

.malumotlar{
  width: 100%;
  height: 120px;
  background: #00BBC7;
}
.asosiy{
  position: absolute;
  left: 530px;
  top: 125px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.4px;
  color: #FFFFFF;  
}
/* yangilar */
.qidr{
  box-sizing: border-box;
  height: 40px;
  width: 467.302490234375px;  
position: absolute;
background: #FFFFFF;
border: 1px solid #E4E4E4;
border-radius: 6px;
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 22px;
letter-spacing: -0.2px;
color: #1C1C1C;
opacity: 0.8;
padding-right: 400px;
left: 360px;
top: 240px;
}
.lupa{
  position: absolute;
  left: 430px;
  top: 10px;
}
.filtr{
  box-sizing: border-box;

position: absolute;
height: 40px;
width: 147.4875030517578px;
background: #FFFFFF;
border: 1px solid #E4E4E4;
border-radius: 6px;
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 22px;
letter-spacing: -0.2px;
color: #1C1C1C;
opacity: 0.8;
left: 850px;
top: 240px;
}
aside{
  height: 930px;
  width: 1130px;
  margin-left: 105px;
  position: relative;
  top: 100px;
  display: grid;
  grid-template-columns: 370px 370px 370px;
  grid-template-rows: 290px 290px 290px;
  column-gap: 10px;
  row-gap: 10px;
}
aside div{
  background: #E0F8FF;
border: 1px solid #E0F8FF;
box-shadow: 0px 1px 2px rgba(56, 56, 56, 0.07);
border-radius: 10px;
}
.it{
  width: 370px;
}
.play{
  position: relative;
  left: 150px;
  bottom: 150px;
}
.tanl{
  position: relative;
  height: 22px;
  width: 326px;  
font-family: 'Nunito';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 22px;
letter-spacing: -0.1px;
color: #000000;
text-decoration: none ;
right: 50px;
bottom: 45px;

}
.meta{
  position: relative;
  left: 15px;
  bottom: 35px;
}